@import 'app.scss';

// Stuff from Sowmya for the loader.

.background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: black;
	opacity: 0.5;
}

.center {
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	transform: translate(0, -50%);
	width: 120px;
	height: 120px;
	margin: auto;
}

.spinner {
	border: 7px solid #f3f3f3;
	border-radius: 50%;
	border-top: 7px solid #0174ad;
	border-bottom: 7px solid #c60c30;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

.message {
	color: white;
	font-weight: bold;
	margin-left: -100%;
	margin-right: -100%;
	text-align: center;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.dot1,
.dot2,
.dot3 {
	background: #0174ad;
	width: 5px;
	height: 5px;
	border: double;
	border-color: black;
	border-radius: 50%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 10px;
}

.dot1 {
	animation: jump 1.6s -0.32s linear infinite;
	background: #0174ad;
}
.dot2 {
	animation: jump 1.6s -0.16s linear infinite;
	background: #c60c30;
}
.dot3 {
	animation: jump 1.6s linear infinite;
	background: #f7f7f9;
}

@keyframes jump {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(2);
		transform: scale(2);
	}
}

@media screen and (max-width: 420px) {
	.center {
		position: absolute;
		z-index: 2;
		left: 35%;
		top: 40%;
		transform: translate(0, -50%);
		width: 120px;
		height: 120px;
		margin: auto;
	}
}
