* {
  box-sizing: border-box;
}

:root {
  --app-color-scheme: light;
  color-scheme: var(--app-color-scheme);
}

html,
body,
#root,
div[data-amplify-authenticator],
div[data-amplify-theme] {
  height: 100%;
}

.logo{
  color:red;
}
body {
  background-color: #ffffff;
  overflow-y: scroll;
}

body.awsui-dark-mode {
  background-color: #0e1b2a;
}

.matrix-table {
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  border-collapse: collapse;
  font-size: 1.1rem;

  th {
    border: 1px solid #d6d6d6;
  }

  td {
    border: 1px solid #d6d6d6;
    padding: 10px;
  }
}

.awsui-dark-mode {
  .matrix-table {
    border: 1px solid rgb(95, 107, 122);

    th {
      border: 1px solid rgb(95, 107, 122);
    }

    td {
      border: 1px solid rgb(95, 107, 122);
      padding: 12px;
    }
  }
}

.jsonContainer {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  background-color: #0e1b2ac3;
}

.jsonStrings {
  color: rgb(74, 234, 167);
  
}

.jsonNumbers {
  color: rgb(255, 223, 60);
}

.jsonBool {
  color: rgb(252, 178, 250);
  font-weight: 600;
}

.jsonNull{
  color:rgb(74, 205, 234);
  font-weight: 600;
}

.logo{
  background-image: url("../app/icons/AI.svg");
  display: inline-block;
  width:  30px; /*or 70%, or what you want*/
  height: 30px; /*or 70%, or what you want*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.city-chat-label{
  position: relative;
  bottom: 10px;
}

.city-chat, .human-chat{
  background: #0066A1;
  color: #FFF;
  padding: 5px 0px 0 8px;
  border-radius: 15px;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin: 0 15px 0 0px;
}

.human-msg{
  margin-left: 20px;
  display: inline-block;
  margin: 2px 0 0 45px;
  color: #6B6E70;
}

.margin-b5{
  margin-bottom: 10px;
}

.margin-l10{
  margin-left: 10px;
}

.bold{
  font-weight: bold;
}

.ces-red{
  background-color: #C60C30 !important;
  border: none !important;
  border-radius: 0% !important;
}

.chat-text{
  color: #6B6E70 !important;
  margin-left:45px !important;
}